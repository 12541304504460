import React, { Suspense, lazy } from "react";
import { DndProvider } from "react-dnd";
import { client } from "./config/apollo";
import { ApolloProvider } from "@apollo/client";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const AllBanner = lazy(() => import("./pages/allBanner"));
const FormBanner = lazy(() => import("./pages/form/banner"));
const LayoutBanner = lazy(() => import("./pages/layout/banner"));

export default function Root() {
  return (
    <ApolloProvider client={client}>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Suspense fallback={<></>}>
            <Routes>
              <Route path="banner-manage" element={<AllBanner />} />
              <Route path="banner-manage/form" element={<FormBanner />} />
              <Route path="banner-manage/layout" element={<LayoutBanner />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </DndProvider>
    </ApolloProvider>
  );
}
